<template>
  <div class="main_style">
    <NavBarHome v-if="navBarStyle === '1'" textColor="#000000" :iconUrl="require('@/assets/img/home/logo-2.png')"/>
    <NavBar v-else />
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享区块链积分平台</p>
        <p class="header-desc">
          众享区块链积分平台为企业用户提供可快速落地、高价值的基于区块链的积分平台解决方案。该平台能够提高积分的通兑性和流通性，进而提高积分的使用价值，可灵活应用于对外营销或企业内部管理。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">产品介绍</div>
      <div class="intro-content">
        <p>
          众享区块链积分平台为企业用户提供可快速落地、高价值的基于区块链的积分平台解决方案。该平台能够提高积分的通兑性和流通性，进而提高积分的使用价值，可灵活应用于对外营销或企业内部管理。支持接入多商户，发行多种积分，并支持积分通兑。企业也可在平台上自行定义并发行积分，灵活设置积分奖励规则，以实现自己的业务目标。
        </p>
      </div>
    </div>
    <!-- 核心功能 -->
    <div class="function">
      <div class="commom-title">核心功能</div>
      <div class="content">
        <el-row :gutter="24" class="grid">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-1.png"
                />
              </div>
              <div class="title">积分上下架</div>
              <div class="text">
                积分上架，积分下架，积分编辑
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-2.png"
                />
              </div>
              <div class="title">商户对接</div>
              <div class="text">
                提供对接接口，商户可对接已有商城
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-3.png"
                />
              </div>
              <div class="title">积分发放</div>
              <div class="text">
                积分的持有商户将积分发放给消费者
              </div>
            </div>
          </el-col>
					<el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-4.png"
                />
              </div>
              <div class="title">商城对接</div>
              <div class="text">
                可选择定制化开发web端、小程序端、app端商城系统
              </div>
            </div>
          </el-col>
					<el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-5.png"
                />
              </div>
              <div class="title">积分自动发放</div>
              <div class="text">
                智能合约可制定详尽的发放规则，同时支持多种手续费设置
              </div>
            </div>
          </el-col>
					<el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-6.png"
                />
              </div>
              <div class="title">数据统计</div>
              <div class="text">
                定制化业务数据统计、积分价值管理及用户行为数据统计等
              </div>
            </div>
          </el-col>
					<el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-7.png"
                />
              </div>
              <div class="title">用户管理</div>
              <div class="text">
                用户的审核、冻结与解冻，可拓展自定义权限管理等功能
              </div>
            </div>
          </el-col>
					<el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-8.png"
                />
              </div>
              <div class="title">硬件监控</div>
              <div class="text">
                区块链系统运行状态监控、物理节点状态监控、系统日志
              </div>
            </div>
          </el-col>
					<el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/function-9.png"
                />
              </div>
              <div class="title">区块监控</div>
              <div class="text">
                查看区块数据、交易数据、定制化查询被授权的业务数据
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">产品架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/product/Point/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">产品优势</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/advantage-1.png"
                />
              </div>
              <div class="title">公开透明</div>
              <div class="text">
                积分数据公开透明，用户能够随时核对积分的发行情况。
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/advantage-2.png"
                />
              </div>
              <div class="title">流通性高</div>
              <div class="text">
                利用区块链构建积分通兑平台，提供不同积分之间的更便捷、更可信的兑换渠道，提高积分的流通性，进而提高积分的使用价值。
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/advantage-3.png"
                />
              </div>
              <div class="title">灵活部署</div>
              <div class="text">
                功能组件齐全，对不同IT基础设施准备度的企业用户均有可快速实施部署的方案。
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Point/advantage-4.png"
                />
              </div>
              <div class="title">落账及时</div>
              <div class="text">
                积分发放秒级到账，快速反馈，增强用户信任。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import NavBarHome from "@/components/NavBarHome";
import Footer from "@/components/Footer";
import {scrollMixin} from '@/mixins/scroll.js'
export default {
  mixins: [scrollMixin],
  components: {
    NavBar,
    NavBarHome,
    Footer,
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/product/Point/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    padding-bottom: 70px;
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .function {
    background: #f4f7fc;
    .content {
      @extend .content-width;
      padding-bottom: 20px;
      .line-title {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        div:nth-child(2) {
          padding: 0px 77px;
          font-size: 26px;
          color: #0075ff;
          line-height: 37px;
        }
        div:nth-child(1),
        div:nth-child(3) {
          flex: 1;
          height: 1px;
          background: #0075ff;
        }
      }
      .grid {
        padding-bottom: 26px;
        .grid-content {
          height: 352px;
          margin-bottom: 24px;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 42px 24px 0px;
          .img {
            width: 124px;
            height: 124px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            padding: 24px 0px;
            font-size: 22px;
            font-family: "PingFangSC-Medium";
            color: rgba(0, 0, 0, 0.85);
            line-height: 30px;
          }
          .text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
  .project {
    .project-content {
      @extend .content-width;
      padding-bottom: 70px;
      .grid-content {
        height: 194px;
        background: #f4f7fc;
        display: flex;
        flex-direction: column;
        align-items: center;
				padding:24px 48px 0px;
        .img {
          width: 66px;
          height: 66px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 22px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
					text-align: center;
        }
      }
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
  }
}
</style>
